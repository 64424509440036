import React, { useContext } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { MdLocationOn } from "react-icons/md";
import { rhythm, scale } from "utils/typography";
import { LocaleContext } from "context/locale-context";
import { ThemeContext } from "context/theme-context";
import LocalizedLink from "components/localizedLink";
import useTranslations from "hooks/useTranslations";
import { FaRegCalendarAlt, FaCheck } from "react-icons/fa";
import { localizedDate, mq } from "utils/helper";

const P = styled.p({
  marginBottom: 0,
  fontSize: "85%",
  opacity: 0.8,
});

function ListData({ units }) {
  const tr = useTranslations();
  const locale = useContext(LocaleContext);
  const { colors } = useContext(ThemeContext);

  const isRTL = locale === "ar";
  return (
    <div
      css={{
        backgroundColor: colors.bg,
        "& svg": {
          marginRight: isRTL ? 0 : rhythm(0.2),
          marginLeft: isRTL ? rhythm(0.2) : 0,
        },
      }}
    >
      {units.map((unit) => {
        return (
          <LocalizedLink
            to={`database/unit/${unit.observation_id}`}
            state={{
              modal: true,
              from: "data-archive",
            }}
            key={unit.observation_id}
          >
            <div
              css={{
                display: "flex",
                justifyContent: "space-start",
                backgroundColor: colors.light,
                marginBottom: rhythm(1),
                padding: `${rhythm(1.3)} ${rhythm(1)}`,
                transition: "0.25s",
                ":hover, :active": {
                  boxShadow: "0 2px 3px 0 rgba(0,0,0,0.25)",
                  cursor: "pointer",
                },
                [mq[0]]: {
                  flexDirection: "column",
                },
              }}
            >
              <div
                css={{
                  padding: "0.3rem",
                  marginRight: isRTL ? 0 : rhythm(1),
                  marginLeft: isRTL ? rhythm(1) : 0,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  minWidth: "fit-content",
                  [mq[0]]: {
                    flexDirection: "row",
                    marginBottom: rhythm(0.5),
                  },
                }}
              >
                <P>
                  <FaRegCalendarAlt />
                  {localizedDate(unit["date_of_incident"], "y,M,d")}
                </P>
                <P>
                  <MdLocationOn />
                  {unit[`location_${locale}`]}
                </P>
              </div>
              <div
                css={{
                  ...scale(0.25),
                  fontWeight: "bold",
                  marginRigth: "auto",
                }}
              >
                {unit[`title_${locale}`]}
              </div>
              <div
                css={{
                  padding: "0.3rem",
                  marginLeft: isRTL ? 0 : "auto",
                  marginRight: isRTL ? "auto" : 0,
                  display: "flex",
                  minWidth: "fit-content",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  [mq[0]]: {
                    marginTop: rhythm(0.5),
                    flexDirection: "row-reverse",
                    width: "100%",
                  },
                }}
              >
                <P
                  css={{
                    alignSelf: "end",
                  }}
                >
                  <span css={{ textTransform: "uppercase" }}>
                    {tr("verified")}
                  </span>{" "}
                  <FaCheck />
                </P>
                <P>
                  <span css={{ display: "inline-block" }}>
                    {tr("Incident")}:
                  </span>
                  <span css={{ color: colors.primary }}>
                    {unit["incident_id"]}
                  </span>
                </P>
              </div>
            </div>
          </LocalizedLink>
        );
      })}
    </div>
  );
}

ListData.propTypes = {
  units: PropTypes.array,
};

export default ListData;
