import React, { useState, useContext, useEffect } from "react";
import Layout from "components/layout";
import { graphql } from "gatsby";
import useTranslations from "hooks/useTranslations";
import { ThemeContext } from "context/theme-context";
import useWindowSize from "hooks/useWindowSize";
import PageHeader from "components/PageHeader";
import { LocaleContext } from "context/locale-context";
import Pagination from "rc-pagination";
import "style/pagination.css";
import { rhythm } from "utils/typography";
import { mq } from "utils/helper";
import { VscSettings } from "react-icons/vsc";
import SearchForm from "components/data-page/search-form.js";
import ListData from "components/data-page/data-list.js";
import { slice } from "ramda";

const PER_PAGE = 20;

function DataArchive(params) {
  const data = params.data;
  const nodes = data?.allUnitsJson?.edges?.map((node) => node?.node);
  const [currentPage, setCurrentPage] = useState(1);
  const [observations, setObservations] = useState(slice(0, 20, nodes));
  const [nodesLength, setNodesLenght] = useState(nodes.length);
  const locale = useContext(LocaleContext);
  const [windowWidthSize] = useWindowSize();
  const [isFilltersCollapsed, setIsFilltersCollapsed] = useState(true);

  const [query, setQuery] = useState({
    title: "",
    location: "",
    dateBefore: "",
    dateAfter: "",
  });

  useEffect(() => {
    setObservations(
      slice(
        PER_PAGE * (currentPage - 1),
        PER_PAGE * (currentPage - 1) + PER_PAGE,
        nodes
      )
    );
  }, [currentPage]);

  useEffect(() => {
    windowWidthSize < 992
      ? setIsFilltersCollapsed(false)
      : setIsFilltersCollapsed(true);
  }, [windowWidthSize]);

  const isRTL = locale === "ar";
  const tr = useTranslations();
  const { colors } = useContext(ThemeContext);
  return (
    <Layout>
      <PageHeader title="The Archive" desc="config.data_archive.description" />
      <div
        css={{
          backgroundColor: colors.bg,
        }}
      >
        <div
          css={{
            display: "grid",
            gridTemplateColumns: "1fr 2fr",
            maxWidth: rhythm(50),
            paddingTop: rhythm(2),
            margin: "0 auto",
            direction: isRTL ? "rtl" : "ltr",
            [mq[0]]: {
              display: "block",
              minWidth: rhythm(15),
              paddingTop: rhythm(0.5),
            },
          }}
        >
          <div
            css={{
              maxWidth: "90%",
              margin: "0 auto",
              marginLeft: isRTL ? "auto" : 0,
              marginRight: isRTL ? 0 : "auto",
              [mq[1]]: {
                maxWidth: "100%",
              },
            }}
          >
            <div
              css={{
                display: "none",
                [mq[1]]: {
                  display: "flex",
                  width: "90%",
                  margin: `${rhythm(1)} auto`,
                },
              }}
            >
              <p css={{ width: "50%", direction: isRTL ? "rtl" : "ltr" }}>
                {tr("videos")}: 3,314,265 {tr("collected")} (5,743{" "}
                {tr("verified")})
              </p>
              <>LOL</>
              <VscSettings
                onClick={() => setIsFilltersCollapsed(!isFilltersCollapsed)}
                css={{
                  cursor: "pointer",
                  fontSize: "130%",
                  marginLeft: isRTL ? 0 : "auto",
                  marginRight: isRTL ? "auto" : 0,
                }}
              />
            </div>
            {isFilltersCollapsed && (
              <div css={{ width: "90%", margin: "auto", paddingTop: "1.4rem" }}>
                <SearchForm
                  query={query}
                  setQuery={setQuery}
                  data={nodes}
                  setObservations={setObservations}
                  setCurrentPage={setCurrentPage}
                  setNodesLenght={setNodesLenght}
                />
              </div>
            )}
          </div>
          <div>
            <Pagination
              current={currentPage}
              total={nodesLength}
              pageSize={20}
              onChange={(current) => setCurrentPage(current)}
            />
            <ListData units={observations} />
            <Pagination
              current={currentPage}
              total={nodesLength}
              pageSize={20}
              onChange={(current) => setCurrentPage(current)}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default DataArchive;

export const pageQuery = graphql`
  query DataArchive {
    allUnitsJson {
      edges {
        node {
          id
          observation_id
          incident_id
          latitude
          link
          location_en
          location_ar
          longitude
          verified
          date_of_incident
          collection
          summary_en
          title_en
          title_ar
          summary_ar
        }
      }
    }
  }
`;
