import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { LocaleContext } from "context/locale-context";
import { ThemeContext } from "context/theme-context";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { last, range, isNil, is } from "ramda";
import { rhythm } from "utils/typography";
import useTranslations from "hooks/useTranslations";

import styled from "@emotion/styled";
import { mq } from "utils/helper";
import "style/react-day-picker.css";

const Input = styled.input(({ colors }) => ({
  border: `2px solid ${colors.dark}`,
  width: rhythm(10),
  height: "2.5rem",
  padding: "0.4rem",
  paddingTop: "0.6rem",
  "::placeholder": {
    fontWeight: "bold",
    color: colors.dark,
    opacity: 1,
  },
  [mq[0]]: {
    minWidth: rhythm(10),
    margin: "0 auto",
  },
}));

const FormOption = styled.div(({ colors }) => ({
  marginBottom: rhythm(1),
  "& .DayPickerInput input": {
    "::placeholder": {
      fontWeight: "bold",
      color: colors.dark,
      opacity: 1,
    },
    height: "2.2rem",
    padding: "0.6rem 0.4rem 0.4rem 0.4rem",
  },
}));

const Label = styled.label({
  display: "block",
  textTransform: "uppercase",
  fontSize: "80%",
  fontWeight: 500,
});

const Li = styled.li({
  fontWeight: "bold",
  fontSize: "120%",
  cursor: "pointer",
  padding: `${rhythm(0, 1)} ${rhythm(0.2)}`,
});

const dateOrString = (date) => (isNil(date) ? "" : date);

function SearchForm({ submitAction, currentPage, pageCount, query, setQuery }) {
  const tr = useTranslations();
  const locale = useContext(LocaleContext);
  const { apiUrl, colors } = useContext(ThemeContext);

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        submitAction(
          `${apiUrl}/search/?title=${query.title}&location=${query.location}&dateBefore=${query.dateBefore}&dateAfter=${query.dateAfter}&lang=${locale}`
        );
      }}
    >
      <FormOption colors={colors}>
        <Label>{tr("Search Term")}</Label>
        <Input
          type="text"
          value={query.title}
          onChange={(event) =>
            setQuery({ ...query, title: event.target.value })
          }
          placeholder={tr("Title")}
          colors={colors}
        />
      </FormOption>
      <FormOption colors={colors}>
        <Label>{tr("Before Date")}</Label>
        <DayPickerInput
          onDayChange={(day) =>
            setQuery({ ...query, dateBefore: dateOrString(day) })
          }
        />
      </FormOption>
      <FormOption colors={colors}>
        <Label>{tr("After date")}</Label>
        <DayPickerInput
          onDayChange={(day) =>
            setQuery({ ...query, dateAfter: dateOrString(day) })
          }
        />
      </FormOption>
      <FormOption colors={colors}>
        <Label>{tr("Location")}</Label>
        <Input
          type="text"
          value={query.location}
          onChange={(e) => setQuery({ ...query, location: e.target.value })}
          placeholder={tr("Location")}
          colors={colors}
        />
      </FormOption>
      <button
        type="submit"
        css={{
          display: "block",
          border: `3px solid ${colors.dark}`,
          padding: "0.4rem 0.6rem 0.14rem 0.6rem",
          fontWeight: "bold",
          backgroundColor: colors.light,
          transition: "0.25s",
          ":hover, :active": {
            backgroundColor: colors.dark,
            color: colors.light,
            cursor: "pointer",
          },
        }}
      >
        {tr("Search")}
      </button>
    </form>
  );
}

const FirstArrow = ({ isRTL }) =>
  isRTL ? <MdKeyboardArrowRight /> : <MdKeyboardArrowLeft />;

const SecondArrow = ({ isRTL }) =>
  isRTL ? <MdKeyboardArrowLeft /> : <MdKeyboardArrowRight />;

function Pagination({
  currentPage,
  pageCount,
  submitAction,
  query,
  unitsLength,
}) {
  const [pagination, setPagination] = useState([2, 3, 4]);
  const [pages, setPages] = useState([1, ...pagination, "...", pageCount]);
  const tr = useTranslations();
  const locale = useContext(LocaleContext);
  const { apiUrl } = useContext(ThemeContext);

  const isRTL = locale === "ar";

  const observationsCount = pageCount == 1 ? unitsLength : pageCount * 50;

  useEffect(() => {
    const p =
      pageCount < 5
        ? range(1, pageCount + 1)
        : [1, ...pagination, "...", pageCount];
    setPages(p);
  }, [pageCount, pagination]);

  return (
    <div
      css={{
        display: "flex",
        justifyContent: "space-between",
        [mq[1]]: {
          margin: `${rhythm(0.5)} 0`,
          display: "block",
        },
      }}
    >
      <span>
        {tr("Observations")}: {observationsCount}
      </span>
      <ul
        css={{
          listStyle: "none",
          display: "flex",
          marginBottom: 0,
          [mq[1]]: {
            marginTop: rhythm(1),
            marginRight: rhythm(2),
            marginLeft: "-0.5rem",
          },
        }}
      >
        <Li
          onClick={() => {
            if (pageCount <= 5) return;
            else if (currentPage > 4) {
              const prevPages = [
                currentPage - 3,
                currentPage - 2,
                currentPage - 1,
              ];
              setPagination(prevPages);
              submitAction(
                `${apiUrl}/search/?title=${query.title}&location=${
                  query.location
                }&dateBefore=${query.dateBefore}&dateAfter=${
                  query.dateAfter
                }&lang=${locale}&page=${currentPage - 1}`
              );
            }
          }}
        >
          <FirstArrow isRTL={isRTL} css={{ fontSize: "120%" }} />
        </Li>
        {pages.map((page) => (
          <Li
            key={page}
            onClick={() => {
              if (!is(Number, page)) return;
              submitAction(
                `${apiUrl}/search/?title=${query.title}&location=${query.location}&dateBefore=${query.dateBefore}&dateAfter=${query.dateAfter}&lang=${locale}&page=${page}`
              );
            }}
            css={{
              borderBottom: currentPage === page && "3px solid black",
            }}
          >
            {page}
          </Li>
        ))}
        <Li
          onClick={() => {
            if (pageCount <= 5) return;
            if (last(pagination) == pageCount - 1) return;
            if (currentPage < pageCount) {
              const nxPages = [
                currentPage + 1,
                currentPage + 2,
                currentPage + 3,
              ];

              setPagination(nxPages);
              submitAction(
                `${apiUrl}/search/?title=${query.title}&location=${
                  query.location
                }&dateBefore=${query.dateBefore}&dateAfter=${
                  query.dateAfter
                }&lang=${locale}&page=${currentPage + 1}`
              );
            }
          }}
        >
          <SecondArrow isRTL={isRTL} css={{ fontSize: "120%" }} />
        </Li>
      </ul>
    </div>
  );
}

SearchForm.propTypes = {
  submitAction: PropTypes.func,
  pageCount: PropTypes.number,
  currentPage: PropTypes.number,
};

export default SearchForm;

export { Pagination };
